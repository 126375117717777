/* Default Post Styles */
.post-container {
    border: 0.5px solid #7d68f5;
    padding: 10px 0;
    border-radius: 20px;
    margin-bottom: 10px;
    position: relative;
    padding: 15px;
    background-color: #ffffff;
    /* Default background color for normal type */
}

/* Post Types */
.post-container.day {
    background-color: #45cfda80;
}

.post-container.week {
    background-color: #efff5380;
}

.post-container.month {
    background-color: #d78cfd80;
}

.post-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.post-profile-picture {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.post-username {
    font-weight: bold;
    color: #000000;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
}

.post-timestamp {
    font-size: 10px;
    color: #000000;
    position: absolute;
    top: 10px;
    right: 15px;
}

.post-content {
    margin-top: 10px;
    color: #1a1a1a;
    font-size: 14px;
    text-align: left;
}

.post-meta {
    margin-top: 10px;
    font-size: 12px;
    color: #000000;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    bottom: 10px;
    right: 10px;
}

.post-icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.post-likes,
.post-comments {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: -15px;
}

.post-meta 

.post-social-icon {
    width: 25px;
    height: 25px;
    margin-right: 210px;
    margin-top: 10px;
    align-self: center; /* Ensures the icon aligns with others in the flex container */
}