/* Profile.css */

.profile-container {
    background-color: #ffffff;
    color: #ffffff;
    padding: 2px;
    min-height: 100vh;
}

.top-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;
}

.create-space-text {
    color: #000000;
    font-size: 10px;
}

.txty-link {
    color: #000000;
    text-decoration: none;
}

.txty-title {
    font-size: 12px;
    color: #4d4d4d;
}

.txty-space {
    color: #7d68f5;
    font-weight: bold;
}

.welcome-message {
    text-align: center;
    font-size: 12px;
}

.profile-section {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.profile-picture {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin-right: 20px;
}

.username {
    color: #7d68f5;
    margin-bottom: 5px;
    display: flex;
    font-size: 18px;
}

.bio {
    color: #747474;
    font-weight: bold;
    margin-right: 2px;
    text-align: left;
}
.social-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.social-links ul {
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
    display: flex;
}

.social-links li {
    margin-right: 2px;
    margin-left: 2px;
    /* Space between icons */
}

.social-links {
    display: flex;
    justify-content: center;
    /* Center the social links horizontally */
    margin-top: 1px;
    /* Add some space above the links */
}
.bio-heading {
    font-size: 18px;
    color: #7d68f5;
    margin-top: 20px;
    text-align: left;
}
.logo-gif {
    width: 50px;
    /* Adjust size as needed */
    height: auto;
    margin-right: 15px;
    /* Space between logo and text */
}
.download-message {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #333333;
}